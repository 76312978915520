import "./Tutorial.scss";
import { Divider, Stack, Typography } from "@mui/material";
import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import { useAuth } from "oidc-react";
import LabeledIconButton from "../../components/common/LabeledIconButton/LabeledIconButton";
import { AccessTime, Badge, Description, Extension, Home, Lightbulb, List, People, ReceiptLong, Storage, WorkspacePremium } from "@mui/icons-material";
import UserDataUtils from "../../utils/UserDataUtils";
import Descriptions from "./descriptions.json";
import { useState } from "react";
import CorsiUseCases from "../../components/tutorial/CorsiUseCases/CorsiUseCases";
import StringUtils from "../../utils/StringUtils";
import ProposteUseCases from "../../components/tutorial/ProposteUseCases/ProposteUseCases";
import CertificazioniUseCases from "../../components/tutorial/CertificazioniUseCases/CertificazioniUseCases";
import TeamUseCases from "../../components/tutorial/TeamUseCases/TeamUseCases";
import UtentiUseCases from "../../components/tutorial/UtentiUseCases/UtentiUseCases";

type tutorialNames = keyof typeof Descriptions;

export default function Tutorial() {
    const [ selectedName, setSelectedName ] = useState<tutorialNames>("dashboard");
    const auth = useAuth();

    const useCaseMap: {[key in tutorialNames]: JSX.Element | undefined} = {
        "dashboard": undefined,
        "corsi": <CorsiUseCases />,
        "proposte": <ProposteUseCases />,
        "certificazioni": <CertificazioniUseCases />,
        "skills": undefined,
        "resoconto": undefined,
        "matrix": undefined,
        "team": <TeamUseCases />,
        "utenti": <UtentiUseCases />,
        "risorse": undefined,
        "database": undefined
    }

    function getOnClickFunction(name: tutorialNames): () => void {
        return (
            () => {
                setSelectedName(name);
            }
        )
    }

    function isSelected(name: tutorialNames): boolean {
        return selectedName === name;
    }

    function getIconList(): JSX.Element[] {
        let icons: JSX.Element[] = [];

        icons.push(<LabeledIconButton Icon={Home} label="Dashboard" size={48} fontSize={18} onClick={getOnClickFunction("dashboard")} selected={isSelected("dashboard")} />);
        if (UserDataUtils.userHasRole(auth.userData!, "team-member")) {
            icons.push(<LabeledIconButton Icon={List} label="Corsi" size={48} fontSize={18} onClick={getOnClickFunction("corsi")} selected={isSelected("corsi")} />);
            icons.push(<LabeledIconButton Icon={AccessTime} label="Proposte" size={48} fontSize={18} onClick={getOnClickFunction("proposte")} selected={isSelected("proposte")} />);
            icons.push(<LabeledIconButton Icon={Description} label="Certificazioni" size={48} fontSize={18} onClick={getOnClickFunction("certificazioni")} selected={isSelected("certificazioni")} />);
            icons.push(<LabeledIconButton Icon={Lightbulb} label="Skills" size={48} fontSize={18} onClick={getOnClickFunction("skills")} selected={isSelected("skills")} />);
            icons.push(<LabeledIconButton Icon={ReceiptLong} label="Resoconto" size={48} fontSize={18} onClick={getOnClickFunction("resoconto")} selected={isSelected("resoconto")} />);
        }
        if (UserDataUtils.userHasRole(auth.userData!, "skills-user")) {
            icons.push(<LabeledIconButton Icon={Extension} label="Matrix" size={48} fontSize={18} onClick={getOnClickFunction("matrix")} selected={isSelected("matrix")} />);
        }
        if (UserDataUtils.userHasRole(auth.userData!, "manager")) {
            icons.push(<LabeledIconButton Icon={People} label="Team" size={48} fontSize={18} onClick={getOnClickFunction("team")} selected={isSelected("team")} />);
        }
        if (UserDataUtils.userHasRole(auth.userData!, "hr")) {
            icons.push(<LabeledIconButton Icon={Badge} label="Utenti" size={48} fontSize={18} onClick={getOnClickFunction("utenti")} selected={isSelected("utenti")} />);
        }
        if (UserDataUtils.userHasRole(auth.userData!, "data-manager")) {
            icons.push(<LabeledIconButton Icon={WorkspacePremium} label="Risorse" size={48} fontSize={18} onClick={getOnClickFunction("risorse")} selected={isSelected("risorse")} />);
        }
        if (UserDataUtils.userHasRole(auth.userData!, "admin")) {
            icons.push(<LabeledIconButton Icon={Storage} label="Database" size={48} fontSize={18} onClick={getOnClickFunction("database")} selected={isSelected("database")} />);
        }
        
        return icons;
    }

    function getTextComponents(text: string): JSX.Element[] {
        let textParts = text.split("\n");
        let components: JSX.Element[] = [];
        textParts.forEach(part => components.push(<Typography fontSize={18}>{part}</Typography>));
        return components;        
    }

    return (
        <div className="Tutorial content">
            <PageLabelBar title="Tutorial" />
            <div className="fullWidth">
                <Stack gap={"32px"} sx={{ marginTop: "24px" }}>
                    <Typography align="center" fontSize={20}>Ecco un elenco delle pagine disponibili. Fai click su una delle icone per ricevere una breve descrizione!</Typography>
                    <Divider />
                    <div className="descriptions">
                        { auth.userData &&
                            <>
                                <Stack direction={"row"} gap={"24px"} sx={{ marginBottom: "48px" }} flexWrap={"wrap"} justifyContent={"center"}>
                                    {getIconList()}
                                </Stack>
                                <Stack borderLeft={(theme) => `4px solid ${theme.palette.divider}`} paddingLeft={"8px"}>
                                    {getTextComponents(Descriptions[selectedName])}
                                </Stack>
                                { useCaseMap[selectedName] !== undefined &&
                                    <>
                                        <Typography fontSize={"24px"} sx={{ marginTop: "24px" }} fontWeight={"bold"}>Casi d'Uso per la pagina {StringUtils.toFirstCharUppercase(selectedName)}</Typography>
                                        { useCaseMap[selectedName] }
                                    </>
                                }
                            </>
                        }
                    </div>
                </Stack>
            </div>
        </div>
    );
}